<template>
  <div>
      <div
        class="d-flex justify-content-center align-items-center p-1"
        style="
          width: 100vw;
          min-height: 100vh;
          background: radial-gradient(#0066cc, #000000);
        "      >
        <div class="card d-flex justify-content-center align-items-center w-100 px-1 py-0" style="min-height: 80vh;" v-if="found">
          <div class="card-header">
            <h3 class="text-center">
              <b>Fila de senhas ({{ senhas.length }} {{ configuracao.tipo_pessoa }} no setor)</b>
              <h5 class="text-center"><b>Você está na {{ posicao }}º posição </b></h5>
            </h3>
          </div>
          <div class="card-body text-center w-100 row d-flex">
            <h2 class="col-12 col-xs-6 col-md-3 col-lg-2 p-1" style="border: 1px solid #aaa;" v-for="senha, index in senhas">
              <b :style="(senha.atendimento_id === senhaAtual.atendimento_id) ? 'color: red' : ''">
                {{ (index+1) + 'º - ' + senha.atendimento.prefixo + senha.atendimento.senha }}
              </b>
            </h2>
          </div>
        </div>
        <div v-else>
          <div class="card d-flex justify-content-center align-items-center w-100 px-1 py-0" style="min-width: 90vw; min-height: 25vh;">
            <h2 class="text-center">A senha procurada não existe ou já foi atendida</h2>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      configuracao: [],
      found: true,
      posicao: 0,
      senhaAtual: [],
      senhas: [],
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getSenha: function (id) {
      const self = this;
      const api = self.$store.state.api + "atendimento_setor?paginated=false&orderByDesc=data_entrada&atendimento_id=" + id

      axios
        .get(api)
        .then((response) => {
          if(!response.data.data.length)
          {
            self.found = false;
            return;
          }

          self.senhaAtual = response.data.data[0];
          
          self.getFila(response.data.data[0].setor_id);

          setInterval(() => {
            self.getFila(response.data.data[0].setor_id);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFila: function (id) {
      const self = this;
      const api = self.$store.state.api + "fila-pessoa/" + id

      axios
        .get(api)
        .then((response) => {
          self.senhas = response.data;

          self.senhas.forEach((senha, index) => {
            if(senha.atendimento_id === self.senhaAtual.atendimento_id)
            {
              self.posicao = (index+1);
            }
          });

          self.senhas.push();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;

    self.getConfiguracao();

    if (id) {
      self.getSenha(id);
    }
  },
  created() {},
};
</script>

<style>
html,
body {
  font-family: helvetica;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1.3s ease-in-out;
  -moz-animation: fadeIn 1.3s ease-in-out;
  -o-animation: fadeIn 1.3s ease-in-out;
  animation: fadeIn 1.3s ease-in-out;
}

body {
  overflow-x: hidden;
}
div > #mensagens {
  display: relative;
  width: 200%;
  grid-template-columns: repeat(2, 0.25fr);
  justify-content: space-between;
  transform: translateX(100z);
  animation: anima 90s linear infinite;
}
#mensagens:before,
#mensagens:after {
  content: "";
  display: relative;
}
#mensagens:after {
  transform: translate3d(100%, 0, 0);
}
@keyframes anima {
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(-160%);
  }
}
</style>
